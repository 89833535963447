:root {
  --warning: #e2c103;
  --secondary: #161616;
  --white: #fff;
}

.App {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  color: var(--white);
  font-family: futur-book;
  font-size: 17px;
  overflow-x: hidden;
}

video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}



@font-face {
  font-family: futur;
  src: url(./font/futur.ttf);
}

@font-face {
  font-family: futur-book;
  src: url(./font/Futura\ Book\ font.ttf);
}

@font-face {
  font-family: futur-bold;
  src: url(./font/Futura\ Bold\ font.ttf);
}

body {
  margin: 0;
  font-family: "coder", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif,"futur-bold";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0cbc41;
  border-radius: 5px;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


html {
  background-color: "#4C4C4C";
}

div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  
}




/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< lg screen >>>>>>>>>>>>>>>>>>>>>>>>>>>>> */

.saleEnd {
  padding: 6em 0;
}

.wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-family: futur-book;
  text-align: center;
  
}
.wrapper h1 {
  margin-top: 0;
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 0;
  padding: 14px;
  font-family: futur-book;
  line-height: 1.2;
  color: #0cbc41;
  
}
.wrapper .mintingSection {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  border: 4px solid #0cbc41;
  border-radius: 2em;
  padding: 3em 1em;
}
.wrapper h4 {
  font-size: 2.2em;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: futur-book;
  font-weight: 500;
}
.wrapperd {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
  font-family: futur-book;
  text-align: center;
  
}
.wrapperd h1 {
  margin-top: 0;
  font-size: 3em;
  font-weight: 700;
  margin-bottom: 0;
  padding: 14px;
  font-family: futur-book;
  
  color: #0cbc41;
  background-color: #1f1f1f;
}

.wrapperd h2 {
  font-size: 2.2em;
  margin-top: 0;
 padding-bottom: 20px;
  font-family: futur-book;
  font-weight: 500;
  color: #fff;
  background-color: #1f1f1f;
}
.wrapper h2 {
  font-size: 2.2em;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: futur-book;
  font-weight: 500;
}
.wrapper p {
  font-size: 1.2em;
  margin: 0;
  font-family: futur-book;
  line-height: 1.5;
}
.wrapperd p {
  font-size: 1.2em;
  margin: 0;
  font-family: futur-book;
  line-height: 1.5;
  background-color: #1f1f1f;
}
.wrapperd a {
  font-size: 1.0em;
  margin: 0;
  font-family: futur-book;
  line-height: 1.5;
color: #fff;
}
.wrapper h6 {
  color: blue;
  font-size: 2em;
  margin-bottom: 0;
}
.wrapper .counter {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 350px;
  margin: 1.5em auto;
  border-radius: 3em;
  overflow: hidden;
}
.wrapper .counter button {
  background-color: transparent;
  color: #fa5c7c;
  border: none;
  outline: none;
  box-shadow: none;
  font-size: 3em;
  padding: 0;
  width: 25%;
  cursor: pointer;
  transition: 200ms all ease-in-out;
}
.wrapper .counter button:hover {
  opacity: 0.8;
}
.wrapper .counter span {
  width: 50%;
  margin: 0 0.2em;
  background-color: transparent;
  color: #fa5c7c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
}
.wrapper .mintBtn {
  font-size: 1.5em;
  font-weight: 700;
  border: 4px solid #fa5c7c;
  background-color: transparent;
  transition: 200ms all ease-in-out;
  color: #fa5c7c;
  border-radius: 2em;
  padding: 0.5em;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
}
.wrapper .mintBtn:hover {
  color: #fff;
  background-color: #fa5c7c;
}
.mintingSection > p:last-child {
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 1em;
}

/* <<<<<<<<<<<<<<<<<<<<< FOOTER >>>>>>>>>>>>>>>>>>>>>>>  */

#lastFooter {
  background-color: var(--secondary);
  
}



#lastFooter .footerSocial {
  padding-top: 11px;
}

#lastFooter .footerSocial i {
  text-decoration: none;
  color: var(--warning);
  transition: 200ms all ease-in-out;
}

#lastFooter .footerSocial i:hover {
  text-decoration: none;
  color: var(--white);
  background-color: var(--warning);
  border: 1px solid var(--warning);
}

#lastFooter .fop-btm a {
  color: var(--warning);
  font-family: futur-bold;
  text-decoration: none;
}

#lastFooter i {
  width: 45px;
  height: 45px;
  border: 1px solid var(--white);
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: var(--warning);
  background: var(--white);
  font-size: 20px;
  margin-right: 13px;
}

#lastFooters i {
  width: 45px;
  height: 45px;
  border: 1px solid var(--white);
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: var(--warning);
  background: var(--white);
  font-size: 20px;
  margin-right: 13px;
}

#lastFooter img {
  width: 45px;
  height: 45px;
  border: 1px solid var(--white);
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  color: var(--warning);
  background: var(--white);
  font-size: 20px;
  margin-right: 13px;
  margin-left: 610px;
}
/* CONTACT SECTION  */

#contactSection {
  padding: 2em 0;
  color: var(--whiteColor);
  background-color: var(--secondary);
}
#contactSection img {
  width: 200px;
}
#contactSection h4 {
  margin-top: 0.5em;
}
#contactSection a {
  color: var(--whiteColor) !important;
  margin-top: 1em;
  display: block;
  text-decoration: none;
}

#contactSection i {
  color: var(--secondary);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 2;
  text-align: center;
  margin-right: 1em;
  background-color: var(--whiteColor);
}